import React from 'react'
import construction from '../../images/under-construction.png'
import constructionMobile from '../../images/under-construction.png'
import './construction.css'

const Construction = () => {
  return (
    <div className='container-fluid'>
      <div className='container'>
        <div className='event-images'>
          <img src={construction} alt="uptop-everyday-events" className='uptop-event-img-desktop' />
          <img src={constructionMobile} alt="uptop-everyday-events" className='uptop-event-img-mobile' />
        </div>
      </div>
    </div>
  )
}

export default Construction
