import { v4 as uuidv4 } from 'uuid';

export const ktmFoods = [
    {
        id: uuidv4(),
        name: 'Steam Mo:Mo',
        meat: 'Beef/Pork/Chicken/Veg',
        price: '12'
    },
    {
        id: uuidv4(),
        name: 'Chilli Mo:Mo',
        meat: 'Beef/Pork/Chicken/Veg',
        price: '13'
    },
    {
        id: uuidv4(),
        name: 'Fried Mo:Mo',
        meat: 'Beef/Pork/Chicken/Veg',
        price: '13'
    },
    {
        id: uuidv4(),
        name: 'Aloo Dum',
        price: '8'
    },
    {
        id: uuidv4(),
        name: 'Beef Shukuti',
        price: '15'
    },
    {
        id: uuidv4(),
        name: 'Chatpate',
        price: '11'
    },
    {
        id: uuidv4(),
        name: 'Sausage on the Stick',
        meat: 'Beef/Chicken',
        price: '12'
    },
    {
        id: uuidv4(),
        name: 'Potato on the Stick',
        price: '12'
    },
    {
        id: uuidv4(),
        name: 'Chips Chilli',
        price: '12'
    },
    {
        id: uuidv4(),
        name: 'Chicken Chilli',
        price: '12'
    },
    {
        id: uuidv4(),
        name: 'Sausage Chilli',
        price: '12'
    },
    {
        id: uuidv4(),
        name: 'Wai Wai Fry',
        price: '12'
    },
    {
        id: uuidv4(),
        name: 'Wai Wai Sadeko',
        price: '9'
    },
    {
        id: uuidv4(),
        name: 'Ramen Fry',
        price: '12'
    },
    {
        id: uuidv4(),
        name: 'Beef Shaybhale',
        price: '12'
    },
    {
        id: uuidv4(),
        name: 'Bhuteko Bhat',
        meat: 'Chicken/Veg',
        price: '13'
    },
    {
        id: uuidv4(),
        name: 'KTM Fries',
        price: '8'
    },
    {
        id: uuidv4(),
        name: 'KTM Wings',
        price: '13'
    },
    {
        id: uuidv4(),
        name: 'Bhatmas/Peanuts Sadeko',
        price: '9'
    },
    {
        id: uuidv4(),
        name: 'Sukuti Sadeko',
        price: '15'
    },
]

export const titsAndBits = [
    {
        id: uuidv4(),
        name: 'KTM Fries / Sweet Potato Fries',
        description: 'fries served with regular ketchup/ spicy mayo/hot and sweet ketchup',
        price: '8'
    },
    {
        id: uuidv4(),
        name: 'Chicken Wings',
        meat: 'BBQ/Buffalo/Schezuan',
        description: 'Crispy and juicy deep fried chicken',
        price: '12'
    },
    {
        id: uuidv4(),
        name: 'Onion rings',
        description: 'Deep fried onion fritters',
        price: '6'
    },
    {
        id: uuidv4(),
        name: 'Spring Rolls',
        meat: '- Veg/Shrimp',
        description: 'Deep fried spring rolls made with veggies and or shrimp',
        price: '8'
    },
    {
        id: uuidv4(),
        name: 'Beef meat balls',
        description: 'Deep fried homemade Meat balls made with house special spices',
        price: '8'
    },
    {
        id: uuidv4(),
        name: 'Pyaazi Pakoda',
        description: 'Onions and chillies mixed in gram flour and spices to make delicious fritter',
        price: '8'
    },
    {
        id: uuidv4(),
        name: "Edamame",
        description: 'Boiled edamame served with salt on side',
        price: '6'
    },
]

export const streetsOfKtm = [
    {
        id: uuidv4(),
        name: 'On the stick',
        meat: '- Chicken or Beef Sausage/Potatoes',
        description: 'Deep fried base on stick is dusted with spices',
        price: '12'
    },
    {
        id: uuidv4(),
        name: 'Chhoila',
        meat: '- Chicken/Beef',
        description: 'Spiced and grilled meat mixed with spicy chillies, scallions and mustard oil originated from newar community',
        price: '10'
    },
    {
        id: uuidv4(),
        name: 'Pani Puri',
        description: 'crispy hollow balls filled with spicy boiled potatoes mixture and served with tamarind water',
        price: '9'
    },
    {
        id: uuidv4(),
        name: 'Sandheko',
        meat: '- Bhatmaas/Peanuts/Wai Wai/Fries',
        description: 'Freshly cut veggies mixed with spices, a zing of lemon, and then mixed with the choice of base',
        price: '9'
    },
    {
        id: uuidv4(),
        name: 'Kaakro with hariyo khursani ko achar',
        description: 'Julienne cut cucumber served with Nepali style green chilli and garlic crushed pickle',
        price: '6'
    },
    {
        id: uuidv4(),
        name: 'Shyabhale',
        meat: '- Chicken / Beef',
        description: 'Shaybhale is a deep fried bread with house special meat fillings',
        price: '12'
    },
]

export const appetizers = [
    {
        id: 1,
        name: "Rigikur (Sherpa potato pancake)",
        description: '2 pcs potato pancakes made from shredded potatoes eggs, flour served with butter and sauce',
        price: '8'
    },
    {
        id: 4,
        name: 'Hariyo pariyo KTM salad',
        description: 'salads served with khursani dip',
        price: '6'
    },
    {
        id: 7,
        name: 'Piro aloo dum ',
        // meat:'- Chicken/Beef/Pork/Chips/Mushroom',
        description: 'fries served with regular ketchup/ spicy mayo/hot and sweet ketchup',
        price: '8'
    },
    {
        id: 11,
        name: 'Chilli',
        meat: '- Chips/Sausage/Beef/Pork/Chicken',
        description: 'Stir fried in veggies and house special spicy sauce',
        price: '12'
    },
    {
        id: 6,
        name: 'Pork Fry',
        description: 'tir fried pork to create a crispy pork served with hot sauce',
        price: '15'
    },
    {
        id: 12,
        name: 'Aloo Timur',
        description: 'Fried potatoes sauteed in a mix of Nepali spices and scallions',
        price: '8'
    },
    {
        id: 15,
        name: 'Timur haaleko chicken',
        description: 'Fried chicken sauteed in a mix of Nepali spices and scallions',
        price: '10'
    },
    {
        id: 16,
        name: 'Jhatpat-Chatpat',
        meat: '- Regular/Chicken',
        description: 'A flavorful delight made with a mixture of puffed rice, mixture of nuts, spices and freshly cut veggies',
        secondDesc: '* $2 extra for chicken chatpate',
        price: '9'
    },
    {
        id: 17,
        name: 'Pangra ',
        description: 'Chicken Gizzard stir fried in Nepali style)',
        price: '10'
    },
    {
        id: 21,
        name: 'Sukuti',
        meat: '- Beef/Buff',
        description: 'Dehydrated meat is sauteed in special house mix sauce',
        price: '15'
    },
]

export const ktmSpecials = [
    {
        id: uuidv4(),
        name: 'Veggie platter ',
        description: 'created for veggie lovers this platter includes chef picked variety of dishes made of seasonal vegetables',
        price: '15'
    },
    {
        id: uuidv4(),
        name: 'Meat Lover Platter',
        description: 'created for meat lovers this platter includes chef picked variety of meat dishes including pork,chicken and beef',
        price: '20'
    },
    {
        id: 23,
        name: 'Wai Wai Fry  ',
        description: 'Stir fried nepali noodle in spices served with egg on top',
        secondDesc: '* add chicken, beef, pork for extra $2, add all meat $4',
        price: '10'
    },
    {
        id: 24,
        name: 'Chicken keema noodles ',
        description: 'Boiled noodles with chilli oil, garlic and soya sauce on bottom and topped with special cooked minced chicken and bokchoy',
        price: '12'
    },
    {
        id: 25,
        name: 'Ramen Fry',
        meat: '- Regular/Spicy Ramen',
        description: 'Stir fried korean noodle in spices served with egg on top',
        secondDesc: '* add chicken, beef, pork for extra $2, add all meat $4',
        price: '10'
    },
    {
        id: 26,
        name: 'Holi - Macaroni',
        meat: '- Veg/Chicken/Pork/Beef/Mixed',
        description: '(Elbow shaped pasta stir fried in Nepali style with veggies and spices',
        secondDesc: '* add chicken, beef, pork for extra $2, add all meat $4',
        price: '10'
    },
    {
        id: 27,
        name: 'Khasi ko Pakku',
        description: 'slow cooked goat meat is marinated in special spices and herbs served with flat rice on side',
        price: '15'
    },
    {
        id: 28,
        name: 'Fried rice',
        meat: 'Regular/Schezuan',
        description: 'An elbow shaped pasta sauteed in nepali style with veggies and a choice of meat',
        secondDesc: '* add chicken,shrimp, beef, pork,egg,sukuti for extra  $2, add all meat $5',
        price: '10'
    },
    {
        id: 29,
        name: 'Chowmein',
        description: 'Nepali style stir fried noodle served with house special chilli oil',
        secondDesc: '* add chicken,shrimp, beef, pork,egg,sukuti for extra  $2, add all meat $5',
        price: '10'
    },
    {
        id: uuidv4(),
        name: 'Thenthuk (Dry/ soup)',
        description: 'Hand pulled noodles served dry or with soup includes vegetables and add ons of meat',
        secondDesc: '* add chicken, beef, pork  $2, add all meat $5',
        price: '14'
    },
    {
        id: 30,
        name: 'Egg rice bowl',
        description: 'Nepali style Buddha bowl comes with rice or cauliflower rice, beans, salad  boiled eggs',
        price: '11'
    },
    {
        id: 31,
        name: 'Chicken rice bowl',
        description: 'Nepali style Buddha bowl comes with rice or cauliflower rice, beans, salad and chicken curry',
        price: '13'
    }
]

export const momos = [
    {
        id: 32,
        name: 'Veg Mo:Mo',
        secondDesc: 'Steamed/Fried/Jhol/Chilli/Kothey(Pan fried)',
        price: ' 10/11/11.50/12/11'
    },
    {
        id: 33,
        name: 'Chicken Mo:Mo',
        secondDesc: 'Steamed/Fried/Jhol/Chilli/Kothey(Pan fried)',
        price: ' 11/12/12.50/13/12'
    },
    {
        id: 34,
        name: 'Pork Mo:Mo',
        secondDesc: 'Steamed/Fried/Jhol/Chilli/Kothey(Pan fried)',
        price: ' 11/12/12.50/13/12'
    },
    {
        id: 35,
        name: 'Beef Mo:Mo',
        secondDesc: 'Steamed/Fried/Jhol/Chilli/Kothey(Pan fried)',
        price: ' 12/13/13.50/14/13'
    },
    {
        id: 36,
        name: 'Assorted Mo:Mo Platter ',
        description: 'A set of 4 pieces of each type of Mo:Mo either steamed or deep fried',
        price: '19'
    },
    {
        id: 37,
        name: 'Mo:Mo Platter',
        meat: '- Veg/Chicken/Pork/Beef',
        description: 'A set of 4 steamed, 4 fried, 4 Chilli, and 4 kothey Mo:Mo',
        price: '23'
    }
]
export const dhalBhat = [
    {
        id: 38,
        name: 'Veg Thali',
        price: '12'
    },
    {
        id: 39,
        name: 'Chicken Thali ',
        price: '15'
    },
    {
        id: 40,
        name: 'Pork Thali',
        price: '15'
    },
    {
        id: 41,
        name: 'Sukuti Thali',
        meat: '- Beef/Buff',
        price: '16'
    },
    {
        id: 42,
        name: 'Mutton Thali',
        price: '16'
    },
]

export const sides = [
    {
        id: 43,
        name: 'Mixed veg curry',
        price: '6'
    },
    {
        id: 44,
        name: 'Chicken curry',
        price: '8'
    },
    {
        id: 45,
        name: 'Mutton curry',
        price: '10'
    },
    {
        id: 46,
        name: 'Plain rice',
        price: '3'
    },
    {
        id: 47,
        name: 'Cauliflower rice',
        price: '5'
    },
    {
        id: 48,
        name: 'Roti (2 pieces)',
        price: '3'
    },
    {
        id: 49,
        name: 'T-Mo:Mo (2 pieces)',
        price: '3'
    },
]

export const desserts = [
    {
        id: 50,
        name: 'I-Scream',
        meat: '- Vanilla/ Chocolate',
        description: 'double scoop with chocolate syrup on top',
        price: '6'
    },
    {
        id: 51,
        name: 'Sugar Bomb ',
        description: 'Kulfi ice cream topped with balls of gulab jamun',
        price: '9'
    },
    {
        id: 52,
        name: 'Black Forest',
        price: '6'
    },
    {
        id: 53,
        name: 'Mochi',
        meat: 'Green tea/Vanilla/Strawberry',
        price: '6'
    },
    {
        id: 54,
        name: 'Kheer',
        description: 'sweet, chilled rice pudding with nuts',
        price: '6'
    },
]

export const beverages = [
    {
        id: 55,
        name: 'Frooti',
        price: '2'
    },
    {
        id: 56,
        name: 'Green tea',
        price: '2'
    },
    {
        id: 57,
        name: 'Lemon Tea',
        price: '2'
    },
    {
        id: 58,
        name: 'Nepali tea',
        price: '2'
    },
    {
        id: 59,
        name: 'Masala tea',
        price: '3'
    },
    {
        id: 60,
        name: 'Nepali Coffee',
        meat: '- Black/Milk',
        price: '3'
    },
    {
        id: 61,
        name: 'Hot Chocolate',
        price: '5'
    },
    {
        id: 62,
        name: 'Masala  Lemonade',
        price: '5'
    },
    {
        id: 63,
        name: 'Green tea matcha',
        meat: '- Hot/Cold',
        price: '5'
    },
    {
        id: 64,
        name: 'Lassi',
        meat: 'Mango/Plain/Strawberry',
        price: '5'
    }
]

export const breakfast = [
    {
        id: 64,
        name: 'Donut',
        price: '2'
    },
    {
        id: 65,
        name: 'Fried T Mo:Mo (2 pieces)',
        price: '4'
    },
    {
        id: 66,
        name: 'Puri and aloo chana',
        price: '10'
    },
    {
        id: 67,
        name: 'Roti and aloo dum',
        price: '10'
    }
]
