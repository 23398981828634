import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import Drink from './drink/drink.component'
import Food from './food/food.component'
import Footer from './footer/footer.component'
// import Album from './ImageGallery/album/album.component'
import Landing from './landing/landing.component'
import Navbar from './navigation/navbar.component'
import KaraokeInfo from './landing/karaoke/karaokeInfo.component'
import Construction from './construction/Construction'

const NavRoutes = () => {
  return (
    <Router>
      {/* <Navbar /> */}
      <Routes>
        <Route exact path="*" element={<Construction />} />
        {/* <Route exact path="/" element={<Landing />} /> */}
        {/* <Route exact path="/food-menu/:id" element={<Food />} /> */}
        {/* <Route exact path="/drink-menu/:id" element={<Drink />} /> */}
        {/* <Route exact path='/albums' element={<Album />} /> */}
        {/* <Route exact path="/karaoke/info" element={<KaraokeInfo />} /> */}
      </Routes>
      {/* <Footer /> */}
    </Router>
  )
}

export default NavRoutes
