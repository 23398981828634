import './App.css';
import NavRoutes from './components/routes.component';


function App() {
  return (
    <div className='main-bg'>
      <NavRoutes />
    </div>
  );
}

export default App;
