import React, { useRef } from 'react'

import './neon.css'
import useOnScreen from '../../../hooks/useOnScreen'

const NeonLogo = () => {

  const ref = useRef(null)
  const isVisible = useOnScreen(ref)

  return (
    <div className='container-fluid'>
      <div className='container'>
        <div className='neon-text-wrapper' ref={ref}>
          <h1 className={`logo ${isVisible && 'animate-logo'}`}>Meet Me @ UPTOP</h1>
        </div>
      </div>
    </div>
  )
}

export default NeonLogo
